/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Poppins', sans-serif;
}

@layer components {

    /* MENU STYLES */
    .menu-vikingo {
        @apply px-4 py-2 flex items-center text-primary hover:bg-secondary10 hover:text-secondary cursor-pointer;
    }

    /* TEXT STYLES */
    .text-vikingo-h2-secondary-bold {
        @apply text-h2 text-secondary font-bold;
    }

    .text-vikingo-h4-neutral90-bold {
        @apply text-h4 text-text90 font-bold;
    }

    /* BUTTONS STYLES */
    .btn-vikingo-primary {
        @apply h-[50px] rounded-xl bg-secondary text-white hover:bg-secondaryDark disabled:bg-primary10 disabled:text-text70;
    }

    .btn-vikingo-outlined {
        @apply h-[50px] rounded-xl bg-white text-primary75 border border-primary75 hover:border-primaryDark hover:text-primaryDark;
    }
}